/* You can add global styles to this file, and also import other style files */
html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  background-color: #e7ebee;
  -webkit-font-smoothing: antialiased;
}

button {
  font-family: "Nunito", sans-serif;
}

.disable-pointer-events {
  pointer-events: none;
  cursor: none;
}

a {
  text-decoration: none;
  color: #26a69a;
}

/*a:hover {*/
/*text-decoration: underline;*/
/*}*/

md-toolbar-row {
  justify-content: space-between;
}

md-sidenav-container {
  height: 100vh;
}

md-sidenav {
  width: 400px;
}

md-button {
  border-radius: 18px;
  box-shadow: none;
}

md-divider {
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
}

.mat-tab-group.mat-tab-fill-height .mat-tab-body-wrapper {
  flex: 1 1 100%;
}

.mat-tab-group.mat-tab-fill-height {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
}

.mat-dialog-container {
  max-height: calc(100vh - 32px) !important;
  max-width: calc(100vw - 32px) !important;
  overflow: auto !important;
  padding: 24px 24px;
}

.block-edit-dialog .mat-dialog-container {
  padding: 0px;
  overflow: hidden !important;
  display: flex;
  flex-direction: column;
}

.block-edit-dialog {
  max-height: 800px !important;
  max-width: 1100px !important;
  min-width: 700px;
  /* min-height: 500px; */
}

.no-padding .mat-dialog-container {
  padding: 0px;
}

.icon-20 {
  font-size: 20px;
}

.mat-tooltip {
  font-size: 12px !important;
}

.mat-simple-snackbar-action {
  font-weight: bold;
  color: white;
}

.mat-placeholder-required {
  display: none;
}

* {
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
}

.mat-dialog-container h4 {
  margin-top: 0px;
}

.port-highlight {
  fill: #cdcdcd;
}

.joint-element {
  cursor: pointer;
}

.mat-tab-disabled {
  display: none;
}

.color-picker {
  border: none;
  border-radius: 2px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.dialog-tab-body-wrapper {
  height: 400px;
  max-height: 400px;
  overflow: auto;
  padding: 10px 0px;
}

.hyphenate {
  /*overflow-wrap: break-word;*/
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.chip .chip-remove-btn {
  height: 20px;
  width: 20px;
  line-height: 20px;
}

.chip .chip-remove-btn >>> md-icon {
  font-size: 14px;
  line-height: 20px;
  margin-left: -2px;
}

.chip {
  padding: 8px 16px;
  margin: 5px 5px 8px 5px;
  background-color: #f0f0f0;
  font-size: 16px;
  height: 100%;
  border-radius: 20px;
}

/* Spinner */

.sk-fading-circle {
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.subheader {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}

button.mat-flat-button.not-allowed {
  cursor: not-allowed;
}

button .mat-spinner path {
  stroke: white;
}

.mat-table {
  /*overflow: auto;*/
  /*max-height: 500px;*/
  border: 1px #e5e5e5 solid;
  border-radius: 8px;
}

[hidden] {
  display: none !important;
}

.panel {
  border: 1px #e1e1e1 solid;
  border-radius: 4px;
  background: white;
  box-shadow: none !important;
  position: relative;
}

.sidemenu {
  display: flex;
  flex-direction: column;
  min-width: 240px;
  max-width: 240px;
  width: 240px;
  height: 100%;
  margin-right: 12px;
}

.toggle-children-placeholder {
  width: 6px !important;
}

.mat-table.selectable mat-row:hover {
  background: #f6f8f9;
}

.mat-table.selectable mat-row::after {
  right: 16px;
  margin-top: 15px;
  position: absolute;
  display: none;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.35);
  font-family: "Material Icons";
  content: "\E5CC";
}

.mat-table.selectable mat-row:hover::after {
  display: block;
}

.mat-table.selectable mat-row {
  cursor: pointer;
  position: relative;
}

.interaction-editor {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: calc(100vw - 32px);
  height: 600px;
  max-width: 1000px;
  max-height: 100%;
  overflow: auto;
}

.cdk-drag-handle {
  cursor: grab;
}
