
body, li {
    font-size: 16px;
    line-height: 1.45;
}

.mat-body p, .mat-body-1 p, .mat-typography p, p {
    font-size: 16px;
    line-height: 1.45;
    margin: 0 0 1em;
    font-weight: normal;
}

.mat-body, .mat-body-1, .mat-typography, .content-block {
    font-size: 16px;
}

.mat-h1, .mat-typography h1, h1,
.mat-h2, .mat-typography h2, h2,
.mat-h3, .mat-typography h3, h3,
.mat-h4, .mat-typography h4, h4 {
    margin: 0 0 0.5em;
    line-height: 1.2;
    font-weight: normal;
}

.mat-h1, .mat-typography h1, h1 {
    margin-top: 0;
    font-size: 3.157em;
}

.mat-h2, .mat-typography h2, h2 {
    font-size: 2.369em;
}

.mat-h3, .mat-typography h3, h3 {
    font-size: 1.777em;
}

.mat-h4, .mat-typography h4, h4 {
    font-size: 1.333em;
}

.mat-list .mat-list-item .mat-line:nth-child(n+2), .mat-nav-list .mat-list-item .mat-line:nth-child(n+2) {
    font-size: 16px;
    line-height: 1.45;
}


.text-block p {
    font-size: 16px !important;
    line-height: 1.45 !important;
    margin: 0 0 1em !important;
    font-weight: normal !important;
}

.text-block {
    font-size: 16px !important;
    color: inherit !important;
}

textarea {
    font-size: 16px !important;
}

.text-block h1,
.text-block h2,
.text-block h3,
.text-block h4 {
    margin: 0 0 0.5em !important;
    line-height: 1.2 !important;
    font-weight: normal !important;
    font-family: var(--header-font-family);
}

.text-block h1 {
    margin-top: 0 !important;
    font-size: 3.157em !important;
}

.text-block h2 {
    font-size: 2.369em !important;
}

.text-block h3 {
    font-size: 1.777em !important;
}

.text-block h4 {
    font-size: 1.333em !important;
}