@import "~@angular/material/theming";

// Plus imports for other components in your app.

// Include the base styles for Angular Material core. We include this here so that you only
// have to load a single css file for Angular Material in your app.
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$custom-primary-color: (
    50 : #e8faf8,
    100 : #c6f2ed,
    200 : #a1e9e1,
    300 : #7be0d4,
    400 : #5ed9cb,
    500 : #42d2c2,
    600 : #3ccdbc,
    700 : #33c7b4,
    800 : #2bc1ac,
    900 : #1db69f,
    A100 : #edfffc,
    A200 : #bafff4,
    A400 : #87ffec,
    A700 : #6effe8,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$custom-warning-color: (
    50 : #fdeded,
    100 : #fad3d2,
    200 : #f7b6b4,
    300 : #f49896,
    400 : #f18280,
    500 : #ef6c69,
    600 : #ed6461,
    700 : #eb5956,
    800 : #e84f4c,
    900 : #e43d3b,
    A100 : #ffffff,
    A200 : #fffcfc,
    A400 : #ffcac9,
    A700 : #ffb1b0,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$app-primary: mat-palette($custom-primary-color);
$app-accent: mat-palette($custom-primary-color);
$app-warn: mat-palette($custom-warning-color);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

$primary: mat-color($app-primary);
$accent: mat-color($app-accent);
$warn: mat-color($app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include angular-material-theme($app-theme);

$default-font-family: "Nunito", sans-serif;
$heading-font-family: "Montserrat", sans-serif;

$custom-typography: mat-typography-config(
  $font-family: $default-font-family,
  $headline: mat-typography-level(3.157em, 1.2, normal, $heading-font-family), // h1
  $title: mat-typography-level(2.369em, 1.2, normal, $heading-font-family), // h2
  $subheading-2: mat-typography-level(1.777em, 1.2, bold, $heading-font-family), // h3
  $subheading-1: mat-typography-level(1.333em, 1.2, bold, $heading-font-family), // h4
  $display-2: mat-typography-level(1.777em, 1.2, bold, $heading-font-family, 3px), // h3
  $display-1: mat-typography-level(16px, 1.2, bold, $heading-font-family, 3px) // h4
);

@include angular-material-typography($custom-typography);

@include mat-list-typography(
  mat-typography-config(
    $font-family: $default-font-family,
    $subheading-2: mat-typography-level(16px, 1.2, normal)
  )
);

@include mat-button-typography(mat-typography-config($font-family: "Montserrat, sans-serif"));

@include mat-menu-typography(
  mat-typography-config(
    $font-family: $default-font-family,
    $subheading-2: mat-typography-level(16px, 1.2, normal)
  )
);

@include mat-expansion-panel-typography(
  mat-typography-config(
    $font-family: $default-font-family,
    $subheading-2: mat-typography-level(16px, 1.2, normal)
  )
);

.mat-primary-bg {
  background: mat-color($app-primary);
}

// .mat-display-1,
// .mat-typography h3 {
//   text-transform: uppercase;
//   font-size: 16px;
//   color: rgba(0, 0, 0, 0.87);
// }

.mat-typography h3 {
  margin: 32px 0 16px;
}

.mat-typography p {
    font-size: 16px;
}

.mat-typography h3:first-child {
  margin-top: 0px;
}

.mat-form-field-outline-start {
  border-radius: 32px 0 0 32px !important;
  width: 32px !important;
}

.mat-form-field-outline-end {
  border-radius: 0 32px 32px 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0 2.5em 0 2.5em !important;
}

.mat-form-field {
  font-size: 16px;
}

.mat-form-field-outline-end,
.mat-form-field-outline-start,
.mat-form-field-outline-gap {
  border-width: 1px !important;
}

.mat-form-field-hint-spacer {
  flex: none !important;
}

.mat-tab-label, .mat-tab-link {
  font-family: $heading-font-family;
}

.mat-hint {
  margin-left: auto;
}

.mat-menu-panel {
  border-radius: 8px !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
}

.mat-stroked-button, .mat-flat-button {
    border-radius: 24px !important;
    font-family: $heading-font-family !important;
    padding: 4px 24px !important;
}

.mat-flat-button.mat-primary {
    background: $primary;
}

.mat-stroked-button.mat-primary {
    color: $primary;
}

.mat-slide-toggle-content {
    font-size: 14px;
}

.mat-menu-item[disabled] {
    cursor: not-allowed !important;
}

/* Make sure option can span multiple lines */
.mat-select-panel .mat-option {
    line-height: 1.7 !important;
    height: unset !important;
    padding: 8px 16px !important;
    white-space: normal !important;
}