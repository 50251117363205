:root {
  --font-family: "Nunito", sans-serif !important;
  --header-font-family: "Montserrat", "Nunito", sans-serif;

  --color-gray-blue: #86a1af;
  --color-gray-1: #798393;

  --color-light-blue: #e5efff;
  --color-dark-blue: #19489b;

  /** neutrals **/
  --color-neutral-0: #ffffff;
  --color-neutral-1: #f4f5f7;
  --color-neutral-2: #dfe1e6;
  --color-neutral-3: #a5adba;
  --color-neutral-4: #5e6c84;
  --color-neutral-5: #091e42;

  /** primary **/
  --color-primary: #42d2c2;
  --color-primary-rgb: 66, 210, 194;
  --color-primary-contrast: #ffffff;
  --color-primary-contrast-rgb: 255, 255, 255;
  --color-primary-shade: #1bac9e;
  --color-primary-tint: #35cabb;

  /** secondary **/
  --color-secondary: #414c58;
  --color-secondary-rgb: 65, 76, 88;
  --color-secondary-contrast: #ffffff;
  --color-secondary-contrast-rgb: 255, 255, 255;
  --color-secondary-shade: #39434d;
  --color-secondary-tint: #545e69;

  /** tertiary **/
  --color-tertiary: #508ff4;
  --color-tertiary-rgb: 80, 143, 244;
  --color-tertiary-contrast: #ffffff;
  --color-tertiary-contrast-rgb: 255, 255, 255;
  --color-tertiary-shade: #467ed7;
  --color-tertiary-tint: #629af5;

  /** success **/
  --color-success: #ace28c;
  --color-success-rgb: 172, 226, 140;
  --color-success-contrast: #ffffff;
  --color-success-contrast-rgb: 255, 255, 255;
  --color-success-shade: #9be272;
  --color-success-tint: #ccffaf;

  /** warning **/
  --color-warning: #ffd400;
  --color-warning-rgb: 255, 212, 0;
  --color-warning-contrast: #ffffff;
  --color-warning-contrast-rgb: 255, 255, 255;
  --color-warning-shade: #e0bb00;
  --color-warning-tint: #ffd81a;

  /** danger **/
  --color-danger: #ef6c69;
  --color-danger-rgb: 239, 108, 105;
  --color-danger-contrast: #ffffff;
  --color-danger-contrast-rgb: 255, 255, 255;
  --color-danger-shade: #d25f5c;
  --color-danger-tint: #f17b78;

  /** dark **/
  --color-dark: #293643;
  --color-dark-rgb: 41, 54, 67;
  --color-dark-contrast: #ffffff;
  --color-dark-contrast-rgb: 255, 255, 255;
  --color-dark-shade: #24303b;
  --color-dark-tint: #3e4a56;

  /** medium **/
  --color-medium: #858c97;
  --color-medium-rgb: 133, 140, 151;
  --color-medium-contrast: #ffffff;
  --color-medium-contrast-rgb: 255, 255, 255;
  --color-medium-shade: #9aa1ab;
  --color-medium-tint: #b7bec8;

  /** light **/
  --color-light: #ffffff;
  --color-light-rgb: 255, 255, 255;
  --color-light-contrast: #000000;
  --color-light-contrast-rgb: 0, 0, 0;
  --color-light-shade: #e0e0e0;
  --color-light-tint: #ffffff;
}

.disable-pointer-events {
  pointer-events: none;
}

.scroll-shadow {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  z-index: 10;
}

.popover {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.24);
}
